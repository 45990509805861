import React, { useRef, useState } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import Swiper from "react-id-swiper";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

import Arrow from "../../../CommonComponents/ArrowComponent/index";

import { changePage } from "../../../../ac";

import kenoBg from "../../../../img/games/keno.jpg";
import kenoBgWebp from "../../../../img/games/keno.webp";
import kenoBgMobile from "../../../../img/games/mobile/keno.jpg";
import kenoBgMobileWebp from "../../../../img/games/mobile/keno.webp";

import lerBg from "../../../../img/games/ler.jpg";
import lerBgMobile from "../../../../img/games/mobile/ler.jpg";

import ArBg from "../../../../img/games/ar.jpg";

import blackjackBg from "../../../../img/games/gamesPage/blackjack.jpg";

import CrBg from "../../../../img/games/CR.jpg";
import CrBgMobile from "../../../../img/games/mobile/CR.jpg";

import baccaratBg from "../../../../img/games/baccarat.jpg";
import baccaratBgMobile from "../../../../img/games/mobile/baccarat.jpg";

import { Link } from "react-router-dom";

function OurGamesSlider(props) {
  const refSwiper = useRef(null);

  const moveTo = (section) => {
    props.dispatch(changePage(section));
  };

  const [slidesState, slidesWatch] = useState({
    prev: "disabled",
    next: "active",
  });

  const sliders = [
    {
      link: "speed-baccarat",
      image: baccaratBg,
      imageMobile: baccaratBgMobile,
      title: "Speed Baccarat",
      text: "Double the Modes, Triple the Fun",
      status: "ok",
    },
    {
      link: "game-cr",
      image: CrBg,
      imageMobile: CrBgMobile,
      title: "Cocktail Roulette",
      text: "Bet, Sip, and Let the Roulette Roll!",
      status: "ok",
    },
    {
      link: "blackjack",
      image: blackjackBg,
      title: "Blackjack",
      text: "Take your winning seat!",
      status: "ok",
    },
    {
      link: "game-ar",
      image: ArBg,
      title: "Auto Roulette",
      text: "Your favorite roulette in slot hall",
      status: "ok",
    },
    {
      link: "game-ler",
      image: lerBg,
      imageMobile: lerBgMobile,
      title: "Live Roulette",
      text: "Red or Black?",
      status: "ok",
    },
    {
      link: "game-keno",
      image: kenoBg,
      imageWebp: kenoBgWebp,
      imageMobile: kenoBgMobile,
      imageMobileWebp: kenoBgMobileWebp,
      title: "Keno",
      text: "Weight of numbers",
      status: "ok",
    }
  ];

  return (
    <div className="our-games-slider">
      <div className="our-games-slider__arrow-container">
        <div className={"our-games-slider__arrow our-games-slider__arrow_next"}>
          <Arrow
            sliderFunction={() => {
              refSwiper.current.swiper.slideNext();
            }}
            isDisabled={slidesState.next === "disabled"}
            direction="next"
          />
        </div>
        <div className={"our-games-slider__arrow our-games-slider__arrow_prew"}>
          <Arrow
            sliderFunction={() => {
              refSwiper.current.swiper.slidePrev();
            }}
            isDisabled={slidesState.prev === "disabled"}
            direction="prev"
          />
        </div>
      </div>

      <div className={"our-games-slider__container"}>
        <Swiper
          ref={refSwiper}
          spaceBetween={props.isMobile ? 16 : 24}
          slidesPerView={"auto"}
          speed={640}
          loop={true}
          centeredSlides={props.isMobile ? "true" : "false"}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          pagination={{
            clickable: true,
            el: ".our-games-slider__pagination",
            bulletClass: "our-games-slider__pagination-bullet",
            bulletActiveClass: "our-games-slider__pagination-bullet_active",
          }}
          on={{
            slideChange: ({ activeIndex, imagesLoaded }) => {
              slidesWatch({
                prev: activeIndex === 0 ? "disabled" : "active",
                next: imagesLoaded - activeIndex === 1 ? "disabled" : "active",
              });
            },
          }}
        >
          {sliders.map((slider, index) => (
            <SwiperSlide key={slider.title}>
              <Link
                to={`/games/${slider.link}`}
                className={
                  "our-games-slider__slide" +
                  (slider.status === "soon"
                    ? " our-games-slider__slide_soon"
                    : "")
                }
              >
                <picture className="our-games-slider__img">
                  <source
                    srcset={slider.imageMobileWebp}
                    type="image/webp"
                    media={"(max-width: 768px)"}
                  />
                  <source
                    srcset={slider.imageMobile}
                    media={"(max-width: 768px)"}
                  />
                  <source srcset={slider.imageWebp} type="image/webp" />
                  <img src={slider.image} alt={slider.title} />
                </picture>
                <div className="our-games-slider__content">
                  <div className="our-games-slider__title">{slider.title}</div>
                  <div className="our-games-slider__text">
                    <span>{slider.text}</span>
                    {slider.year && (
                      <span className="our-games-slider__year">
                        {slider.year}
                      </span>
                    )}
                  </div>
                </div>
                {slider.status === "soon" ? (
                  <span className="our-games-slider__status">coming soon</span>
                ) : null}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
});
SwiperCore.use([Pagination, Autoplay]);

export default connect(mapStateToProps)(OurGamesSlider);
