import React from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import ItemListComponent from "./ItemListComponent/ItemListComponent";
import kenoBg from "../../../img/games/gamesPage/keno.jpg";
import kenoLogo from "../../../img/games/keno.svg";

import ArBg from "../../../img/games/ar.jpg";
import ArLogo from "../../../img/games/ar.svg";

import lerBg from "../../../img/games/gamesPage/ler.jpg";
import lerBgMobile from "../../../img/games/gamesPage/mobile/ler.jpg";
import lerLogo from "../../../img/games/ler.svg";

import blackjackBg from "../../../img/games/gamesPage/blackjack.jpg";
import blackjacklLogo from "../../../img/games/bj.svg";

import CrBg from "../../../img/games/CR.jpg";
import CrBgMobile from "../../../img/games/gamesPage/mobile/CR.jpg";
import CrLogo from "../../../img/games/CR-logo.svg";

import baccaratBg from '../../../img/games/gamesPage/baccarat.jpg';
import baccaratLogo from '../../../img/games/baccarat.svg';

const gamesListComponent = ({ isMobile }) => {
  const games = [
    {
      link: 'speed-baccarat',
      image: baccaratBg,
      logo: baccaratLogo,
      title: 'Speed Baccarat',
      text: 'Double the Modes, Triple the Fun',
      year: '2024',
      status: 'ok'
    },
    {
      link: "game-cr",
      image: isMobile ? CrBgMobile : CrBg,
      logo: CrLogo,
      title: "Cocktail Roulette",
      text: "Bet, Sip, and Let the Roulette Roll!",
      year: "2023",
      status: "ok",
    },
    {
      link: "blackjack",
      image: blackjackBg,
      logo: blackjacklLogo,
      title: "Blackjack",
      text: "Take your winning seat!",
      year: "2023",
      status: "ok",
    },
    {
      link: "game-ar",
      image: ArBg,
      logo: ArLogo,
      title: "Auto Roulette",
      text: "Your favorite roulette in slot hall",
      year: "2022",
      status: "ok",
    },
    {
      link: "game-ler",
      image: isMobile ? lerBgMobile : lerBg,
      logo: lerLogo,
      title: "Live Roulette",
      text: "Red or Black?",
      year: "2019",
      status: "ok",
    },
    {
      link: "game-keno",
      image: kenoBg,
      logo: kenoLogo,
      title: "Keno",
      text: "Weight of numbers",
      year: "2019",
      status: "ok",
    },
  ];

  return (
    <div className={"games-list"}>
      <div className="games-list__inner">
        <div className="games-list__items">
          {games.map(({ title, text, image, logo, year, status, link }) => (
            <div className="games-list__item" key={title}>
              <ItemListComponent
                title={title}
                text={text}
                bg={image}
                logo={logo}
                date={year}
                status={status}
                link={link}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
});

export default connect(mapStateToProps)(gamesListComponent);
